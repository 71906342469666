import { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import {
  Avatar, Button, List, Tooltip, message, Checkbox, Space
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Content from '../../common/Content';
import { useConversations, checkReceiver } from '../../../lib/requestHandler';
import useGlobalUserState from '../../../lib/useGlobalUserState';
import UserEmailPrompt from './UserEmailPrompt';
import BurnProtocolPrompt from './BurnProtocolPrompt';

const Dashboard = () => {
  const { user } = useGlobalUserState();
  const [isUserEmailPromptOpen, setIsUserEmailPromptOpen] = useState(false);
  const [redirection, setRedirection] = useState(null);

  const [selectingBurn, setSelectingBurn] = useState(false);
  const [selectedToBurn, setSelectedToBurn] = useState([]);
  const [openBurnPrompt, setOpenBurnPrompt] = useState(false);

  const {
    data, loading, refetch 
  } = useConversations(user?.token);
  
  const [messageApi, contextHolder] = message.useMessage();

  const onUserEmailPassed = async (val) => {
    setIsUserEmailPromptOpen(false);
    messageApi.open({
      type: 'loading',
      content: 'Connecting...'
    });
    const res = await checkReceiver(user?.token, val?.email, 'email');
    if (res?.data?.status) {
      setRedirection(`/dashboard/c/${res?.data?.data?.id}`);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Error occured'
      });
    }
  };

  const handleSetSelectingBurn = () => {
    setSelectedToBurn([]);
    setSelectingBurn((prev) => !prev);
  };

  const handleSelectToBurn = (checked, id) => {
    if (checked) {
      setSelectedToBurn((prev) => [...prev, id]);
    } else {
      setSelectedToBurn((prev) => prev.filter((elm) => elm !== id));
    }
  };

  const selectAllToBurn = () => {
    setSelectedToBurn(data?.data.map((elm) => elm.id));
  };

  const initiate = () => {
    setOpenBurnPrompt(true);
  };

  const handleOnBurnSuccess = () => {
    refetch();
    handleSetSelectingBurn();
  };

  if (redirection) {
    return <Navigate to={redirection} />;
  }
  return (
    <Content>
      {contextHolder}
      <UserEmailPrompt
        open={isUserEmailPromptOpen}
        onCreate={onUserEmailPassed}
        onCancel={() => {
          setIsUserEmailPromptOpen(false);
        }}
      />
      <BurnProtocolPrompt
        open={openBurnPrompt} 
        data={selectedToBurn}
        onCancel={() => setOpenBurnPrompt(false)}
        onSuccess={handleOnBurnSuccess}
      />
      <Space style={{ marginBottom: 20 }}>
        {!selectingBurn && (
          <>
            <Button size="large" onClick={() => setIsUserEmailPromptOpen(true)}>New Message</Button>
            <Button size="large" onClick={refetch}>
              <ReloadOutlined />
            </Button>
          </>
        )}
        <Button size="large" onClick={handleSetSelectingBurn} disabled={data?.data.length < 1} danger>
          {selectingBurn ? 'CANCEL' : 'BURN'}
        </Button>
        {selectingBurn && (
          <>
            <Button size="large" onClick={selectAllToBurn}>Select All</Button>
            <Button style={{ fontWeight: 'bold' }} type="primary" danger size="large" onClick={initiate} disabled={selectedToBurn.length < 1}>Initiate</Button>
          </>
        )}
      </Space>
      <List
        className="demo-loadmore-list"
        loading={loading}
        itemLayout="horizontal"
        dataSource={data?.data || []}
        bordered
        renderItem={(item) => (
          <List.Item className="chatListItem">
            <List.Item.Meta
              avatar={(
                <Tooltip title={item.email}>
                  <Avatar>
                    {item.name[0].toUpperCase()}
                  </Avatar>
                </Tooltip>
            )}
              title={<span>{item.name}</span>}
              description={item?.last_date}
            />
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              {selectingBurn ? (
                <Checkbox 
                  checked={selectedToBurn.includes(item.id)}
                  onChange={(event) => handleSelectToBurn(event.target.checked, item.id)}
                >
                  {selectedToBurn.includes(item.id) ? (
                    <p style={{ fontWeight: 'bold', color: '#cf1322' }}>SELECTED</p>
                  ) : (
                    <p style={{ fontWeight: 'bold' }}>select to burn</p>
                  )}
                </Checkbox>
              ) : (
                <Link to={`/dashboard/c/${item.id}`}>
                  <Button size="large">Connect</Button>
                </Link> 
              )}

            </div>
          </List.Item>
        )}
      />
    </Content>
  );
};
export default Dashboard;
