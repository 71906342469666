import useAxios from 'axios-hooks';
import axios from 'axios';
import { saveUserData } from './userDataHandler';

const useConversations = (jwt) => {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${process.env.REACT_APP_BACKEND_URL}/getConversations`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${jwt}`
      }
    }
  );
  if (error?.response?.status === 401) {
    saveUserData(null);
    window.location.reload();
  }
  return {
    data, loading, error, refetch 
  };
};
const getConversationMessages = async (jwt, receiver, page = 0) => {
  if (!receiver) {
    return null;
  }
  try {
    const body = {
      receiver,
      page
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/getConversationMessages`,
      body,
      {
        headers: {
          authorization: `Bearer ${jwt}`,
          Accept: 'application/json'
        }
      }
    );
    if (res?.response?.status === 401) {
      saveUserData(null);
      window.location.reload();
    }
    return res?.data;
  } catch (e) {
    return e;
  }
};
const checkReceiver = async (jwt, receiver, searchBy) => {
  try {
    let body = {
      email: receiver
    };
    if (searchBy === 'id') {
      body = {
        id: receiver
      };
    }
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/checkReceiver`,
      body,
      {
        headers: {
          authorization: `Bearer ${jwt}`,
          Accept: 'application/json'
        }
      }
    );
    return res;
  } catch (e) {
    return e;
  }
};

const sendMessage = async (jwt, receiver, message, file, enforcedMime = false) => {
  try {
    const formData = new FormData();
  
    if (file) {
      formData.append('file', file);
    }

    const body = {
      receiver,
      message,
      ...(enforcedMime ? { userMime: file.type } : {})
    };

    formData.append('data', JSON.stringify(body));

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/sendMessage`,
      formData,
      {
        headers: {
          authorization: `Bearer ${jwt}`,
          Accept: 'application/json'
        }
      }
    );
    return res;
  } catch (e) {
    return e;
  }
};

const downloadFile = async (jwt, path) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/file/${path}`,
      {
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${jwt}`
        }
      }
    );
    return res;
  } catch (e) {
    return e;
  }
};

const burnConversations = async (jwt, channels) => {
  try {
    const body = {
      channels
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/burnConversations`,
      body,
      {
        headers: {
          authorization: `Bearer ${jwt}`,
          Accept: 'application/json'
        }
      }
    );
    return res;
  } catch (e) {
    return e;
  }
};

export {
  useConversations,
  getConversationMessages,
  checkReceiver,
  sendMessage,
  burnConversations,
  downloadFile
};
  
