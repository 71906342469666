import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { setWith, uniqBy, orderBy } from 'lodash';
import {
  App, Button, Input, Tooltip, Breadcrumb, Spin, Card, Empty, Typography, Divider, Upload, Image
} from 'antd';
import {
  SendOutlined, ReloadOutlined, KeyOutlined, LockFilled, UploadOutlined, DownloadOutlined
} from '@ant-design/icons';
import Content from '../../common/Content';
import {
  checkReceiver, getConversationMessages, sendMessage, downloadFile 
} from '../../../lib/requestHandler';
import useGlobalUserState from '../../../lib/useGlobalUserState';
import ChannelEncPrompt from './ChannelEncPrompt';
import { saveLocalEncryption, readLocalEncryption } from '../../../lib/localEncryptionHandler';
import { encryptFn, decryptFn } from '../../../lib/encryption';
import convertToNato from '../../../lib/natoPhonetic';
import { encryptFile, decryptFile } from '../../../lib/file';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;

const encPrefix = '<<=/ENC2/=>>';
const encSeparator = '#/';

const DisplayFile = ({
  path, fileName, encKey, jwt, setIsEncrypted
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [encError, setEncError] = useState(false);

  const fetchData = async () => {
    const res = await downloadFile(jwt, path);
    let err = false;

    const preparedFile = await decryptFile(res?.data, res?.headers['content-type'], (val) => {
      if (val.startsWith(encPrefix)) {
        setIsEncrypted(true);
        const decrypted = decryptFn(val.replace(encPrefix, ''), convertToNato(encKey, encSeparator));
        if (decrypted) {
          return decrypted;
        } 
        setEncError(true);
        err = true;
        return undefined;
      }
      return null;
    });
    if (preparedFile) {
      res.data = preparedFile;
    }
    if (err) {
      res.data = undefined;
    }
    return res;
  };

  useEffect(() => {
    const init = async () => {
      if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(fileName)) {
        setLoading(true);
        setDisplay(true);

        const res = await fetchData();
        const type = res?.headers['content-type'];
        if (type.includes('image') && res?.data) {
          setData(URL.createObjectURL(res?.data));
        }

        setLoading(false);
      }
    };
    init();
  }, []);

  const download = async () => {
    setLoading(true);
    const res = await fetchData();
    if (encError || !res?.data) {
      setLoading(false);
    } else {
      const url = window.URL.createObjectURL(res?.data);
      window.open(url);
      setLoading(false);
    }
  };

  if (display && loading && !data) {
    return (
      <Spin />
    );
  }
  if (!loading && encError) {
    return (
      <Text style={{ color: '#cf1322', fontStyle: 'italic' }}>Decryption failed</Text>
    );
  }
  if (display && !loading && data) {
    return (
      <div>
        <Image
          width={200}
          src={data}
        />
      </div>
    );
  }
  return (
    <Button onClick={download} loading={loading} icon={<DownloadOutlined />} className="download-btn">
      <Text>{fileName}</Text>
    </Button>
  );
};

const MessageCard = ({
  id, content, date, align, encKey, type, fileName, jwt
}) => {
  const [isEncrypted, setIsEncrypted] = useState(content.startsWith(encPrefix));

  const displayMsg = (msg) => {
    if (msg.startsWith(encPrefix)) {
      const decrypted = decryptFn(msg.replace(encPrefix, ''), convertToNato(encKey, encSeparator));
      return decrypted || <Text style={{ color: '#cf1322', fontStyle: 'italic' }}>Decryption failed</Text>;
    }
    return msg;
  };

  return (
    <Card
      style={{ minWidth: 'min-content', ...(align === 'right' ? { marginLeft: 'auto' } : { marginRight: 'auto' }) }}
      bordered
      bodyStyle={{ padding: '10px 15px 10px 15px' }}
      key={id}
      className={`chatMessage-${id}`}
    > 
      <Paragraph style={{ fontSize: 12, marginBottom: '0.4em' }} type="secondary">
        {date} 
        {' '}
        {isEncrypted && <LockFilled style={{ position: 'relative', bottom: 1 }} />}
      </Paragraph>
      {type === 'file' ? (
        <DisplayFile
          path={content}
          fileName={fileName}
          encKey={encKey}
          jwt={jwt}
          setIsEncrypted={setIsEncrypted}
        />
      ) : (
        <Text style={{ fontSize: '1.1rem' }}>{displayMsg(content)}</Text>
      )}
    </Card>
  );
};

const Channel = () => {
  const { id } = useParams();
  const { user, pin } = useGlobalUserState();

  const chatsInnerRef = useRef();
  const refreshBtnRef = useRef();

  const [cData, setCData] = useState(null);
  const [chatsData, setChatsData] = useState(null);

  const [page, setPage] = useState(0);
  const [lockPagination, setLockPagination] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [localEncKey, setLocalEncKey] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const [fileList, setFileList] = useState([]);
  
  const { message } = App.useApp();

  const scrollToMessage = (id, behavior) => {
    const lastElm = document.querySelector(`.chatMessage-${id}`);
    if (lastElm && id) {
      lastElm.scrollIntoView({ bottom: 0, ...(behavior ? { behavior } : {}) });
    }
  };
  
  const [latestMessageDate, setLatestMessageDate] = useState(null);

  const fetchChats = async (staticPage = page) => {
    const res = await getConversationMessages(user?.token, id, staticPage);
    const rows = res?.data;
    if (rows) {
      if (chatsData) {
        setLatestMessageDate(chatsData[chatsData.length - 1]?.created_at);
      }

      if (rows.length === 0) {
        setLockPagination(true);
      }
      let newChats = [
        ...rows,
        ...(chatsData || [])
      ];
      newChats = uniqBy(newChats, 'id');
      newChats = orderBy(newChats, [(obj) => dayjs(obj.created_at)], ['asc']);
      setChatsData(newChats);
    }
  };

  useEffect(() => {
    fetchChats();
  }, [page]);

  useEffect(() => {
    const check = async () => {
      const res = await checkReceiver(user?.token, id, 'id');
      if (res?.data?.status) {
        setCData(res?.data?.data);
      } else {
        window.location.replace('/dashboard');
      }
    };
    check();
  }, []);

  const [messageText, setMessageText] = useState('');

  const handleSendMessage = async () => {
    if (messageText || fileList.length > 0) {
      setIsSending(true);
      let msg = messageText;
      let preparedFile = fileList[0];

      if (localEncKey && msg) {
        const encrypted = encryptFn(msg, convertToNato(localEncKey, encSeparator));
        msg = encPrefix + encrypted;
      }

      if (localEncKey && fileList.length === 1) {
        preparedFile = await encryptFile(preparedFile, (val) => {
          const fileEncrypted = encryptFn(
            val,
            convertToNato(localEncKey, encSeparator)
          );
          return encPrefix + fileEncrypted;
        });
      }
      
      const res = await sendMessage(
        user?.token, 
        id,
        msg, 
        preparedFile, 
        localEncKey && fileList.length === 1
      );

      if (res?.data?.status) {
        message.success('Message sent');
        setMessageText('');
        setFileList([]);
        fetchChats(0);
      } else {
        message.error('Error occured while sending message');
        setIsSending(false);
      }
    }
  };

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(dayjs());

  const onScroll = () => {
    if (chatsInnerRef.current) {
      const { scrollTop } = chatsInnerRef.current;
      if (
        scrollTop === 0 
        && !lockPagination 
        && !refreshLoading 
        && dayjs().diff(lastRefresh) > 500
      ) {
        setPage((current) => current + 1);
      }
    }
  };
  useEffect(() => {
    if (chatsData && chatsData.length > 0) {
      const [{ id: lastId }] = chatsData.slice(-1);
      if (refreshLoading) {
        setRefreshLoading(false);
        const newChats = chatsData
          .filter((elm) => dayjs(latestMessageDate).isBefore(dayjs(elm.created_at)));

        const newCount = newChats.length;
        if (newCount > 0) {
          message.info(`${newCount} new message${newCount > 1 ? 's' : ''}`);
          scrollToMessage(lastId, 'smooth');
        }
      } else if (page > 0 && !isSending) {
        scrollToMessage(chatsData[chatsData.length - (page * 10) - 1]?.id);
      } else {
        scrollToMessage(lastId);
        setIsSending(false);
      }
    }
  }, [chatsData]);

  const refresh = async () => {
    setRefreshLoading(true);
    await fetchChats(0);
    setLastRefresh(dayjs());
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const handleAutoRefresh = () => {
    refreshBtnRef.current.click();
  };

  useEffect(() => {
    const intervalValue = parseInt(window.localStorage.getItem('refreshing_interval'), 10) || 10;
    
    const interval = setInterval(() => {
      handleAutoRefresh();
    }, intervalValue * 1000);
    
    return () => clearInterval(interval);
  }, [lastRefresh]);

  const getCurrentLocalEncKey = () => {
    const storage = readLocalEncryption(pin);
    return storage?.[user?.id]?.[parseInt(id, 10)];
  };
  useEffect(() => {
    setLocalEncKey(getCurrentLocalEncKey());
  }, []);
  const handleOpenModal = () => {
    const current = localEncKey;
    setLocalEncKey(null);
    setTimeout(() => {
      setLocalEncKey(current);
    }, 500);
    setOpenModal(true);
  };

  const handleOnSetLocalEnc = (val) => {
    if (val?.length === 10 || val?.length === undefined) {
      const obj = readLocalEncryption(pin) || {};
      setWith(obj, [user?.id, id], val, Object);
      saveLocalEncryption(obj, pin);
      setLocalEncKey(val);
      setOpenModal(false);
      if (val?.length === undefined) {
        message.info('Advanced Local Encryption disabled');
      } else {
        message.success('Advanced Local Encryption enabled');
      }
    } else {
      message.error('Encryption passphrase must be 10 characters');
    }
  }; 

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    maxCount: 1,
    disabled: messageText
  };

  if (!chatsData) {
    return (
      <Content style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 
      }}
      >
        <Spin tip={`Connecting to C-${id}`} size="large" />
      </Content>
    );
  }
  return (
    <Content style={{ marginTop: 20, paddingTop: 12 }} className="channel">
      <ChannelEncPrompt
        id={id}
        value={localEncKey}
        open={openModal}
        onCreate={handleOnSetLocalEnc}
        onCancel={() => { setOpenModal(false); }}
      />
      <div className="channel-menu" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Breadcrumb
            items={[
              {
                href: '/dashboard',
                title: 'Dashboard'
              },
              {
                title: `C-${id}`
              }
            ]}
          />
          <h2>{cData?.name}</h2>
        </div>
        <div
          className="channel-menu-right"
          style={{
            display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap'
          }}
        >
          <Tooltip title={dayjs(lastRefresh).format()}>
            <Text type="secondary">
              {dayjs(lastRefresh).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
          </Tooltip>

          <div style={{
            display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'flex-end' 
          }}
          >
            <Tooltip title={`Advanced Local Encryption - ${localEncKey ? 'ON' : 'OFF'}`}>
              <Button
                onClick={handleOpenModal}
                icon={<KeyOutlined />}
                size="large"
                style={localEncKey ? { background: '#389e0d', color: '#fff' } : {}}
              />
            </Tooltip>

            <Button
              loading={refreshLoading}
              onClick={refresh}
              ref={refreshBtnRef}
              icon={<ReloadOutlined />}
              size="large"
            />
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
      <div
        className="chats-cnt"
        style={{
          display: 'flex', flexDirection: 'column', gap: 10, width: '100%', overflow: 'auto', paddingRight: 10, paddingLeft: 10, paddingBottom: 15
        }}
        ref={chatsInnerRef}
        onScroll={onScroll}
      >
        {chatsData.map((record) => (
          <MessageCard
            key={record.id}
            id={record.id}
            content={record.message}
            date={record.created_at}
            align={record.senderId === user?.id ? 'right' : 'left'}
            encKey={localEncKey}
            type={record.type}
            fileName={record?.fileName}
            jwt={user?.token}
          />
        ))}
        {chatsData.length === 0 && (
          <Empty style={{ marginTop: 10 }} />
        )}
      </div>
      <div
        className="channel-inpts-cnt"
        style={{
          width: '100%', display: 'flex', gap: 5, marginTop: 5 
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Upload {...uploadProps}>
          <Button 
            icon={<UploadOutlined />} 
            size="large"
            style={{ height: '55px', minWidth: 60 }}
            className="channel-inpts-uploadBtn"
            disabled={messageText}
          />
        </Upload>
        <TextArea
          showCount
          maxLength={250}
          autoSize={{ minRows: 2, maxRows: 2 }}
          style={{ width: '100%', height: '55px' }}
          placeholder="your message"
          value={messageText}
          onPressEnter={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          onChange={(e) => setMessageText(e.target.value)}
          disabled={fileList.length > 0}
        />
        <Button
          type="primary"
          size="large"
          icon={<SendOutlined />}
          style={{ height: '55px', minWidth: 60 }}
          onClick={handleSendMessage}
          loading={isSending}
        />
      </div>

    </Content>
  );
};
export default Channel;
