import { encryptFn, decryptFn } from './encryption';

const saveLocalEncryption = (data, pin) => {
  if (data !== null) {
    localStorage.setItem('LED', encryptFn(JSON.stringify(data), pin));
  } else {
    localStorage.removeItem('LED');
  }
};
const readLocalEncryption = (pin) => {
  try {
    const retrievedObject = localStorage.getItem('LED');
    return JSON.parse(decryptFn(retrievedObject, pin)); 
  } catch (e) {
    return null;
  }
};
export { saveLocalEncryption, readLocalEncryption };
