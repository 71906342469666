import {
  Form, Input, Modal 
} from 'antd';

const UserEmailPrompt = ({ open, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title="New receiver selection"
      okText="Connect"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="userSelection"
        size="large"
        style={{ marginTop: 20 }}
        // initialValues={{
        //   email: '<---scan qr---->'
        // }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: 'email'
            }
          ]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>

      </Form>
    </Modal>
  );
};
export default UserEmailPrompt;
