import { useState, useMemo, useEffect } from 'react';
import 'antd/dist/reset.css';
import {
  App as AntdApp, ConfigProvider, theme, Layout, Button
} from 'antd';
import { LockOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Routes, Route, Link } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import { readUserData, saveUserData, logout } from './lib/userDataHandler';
import ProtectedRoute from './components/common/ProtectedRoute';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Settings from './components/pages/Settings/Settings';
import Channel from './components/pages/Channel/Channel';
import Home from './components/pages/Home/Home';

const {
  Header, Content 
} = Layout;
const { darkAlgorithm } = theme;

function App() {
  const storagePinData = window.sessionStorage.getItem('uap');
  const [user, setUserData] = useState(undefined);
  const [pin, setPinData] = useState(storagePinData ? window.atob(storagePinData) : null);
  const setUser = (data) => {
    saveUserData(data, pin);
    setUserData(data);
  };

  const setPin = (val) => {
    if (val) {
      window.sessionStorage.setItem('uap', window.btoa(val));
    } else {
      window.sessionStorage.removeItem('uap');
    }
    setPinData(val);
  };

  const contextData = useMemo(() => ({
    user, setUser, pin, setPin 
  }), [user, pin]);
  useEffect(() => {
    if (pin) {
      const storageUserData = readUserData(pin);
      setUser(storageUserData);
    } else {
      setUserData(null);
    }
  }, [pin]); 

  return (
    <ConfigProvider theme={{
      algorithm: darkAlgorithm 
    }}
    >
      <AntdApp>
        <UserContext.Provider value={contextData}>
          <Layout style={{ minHeight: '100vh', background: '#141414' }}>
            <Header
              className="header"
              style={{
                background: '#000', display: 'flex', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20
              }}
            >
              <Link to="/dashboard">
                <h2 style={{ color: '#fff' }}>ETACC</h2>
              </Link>
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                {pin && (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Button size="large" icon={<LockOutlined />} onClick={() => setPin(null)}>
                    Lock
                  </Button>
                </div>
                )}
                {user && (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Link to="/settings">
                    <Button size="large" icon={<SettingOutlined />} />
                  </Link>
                  <Button size="large" icon={<LogoutOutlined />} onClick={() => logout(pin)}>
                    Logout
                  </Button>
                </div>
                )}
              </div>
            </Header>
            <Content style={{ padding: '0 5px' }}>
              <div style={{
                display: 'flex', justifyContent: 'center', alignContent: 'center'
              }}
              >
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route element={<ProtectedRoute />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/dashboard/c/:id" element={<Channel />} />
                    <Route path="/settings" element={<Settings />} />
                  </Route>
                  <Route path="/" element={<Home />} />
                </Routes>
              </div>
            </Content>
          </Layout>
        </UserContext.Provider>
      </AntdApp>
    </ConfigProvider>

  );
}

export default App;
