import axios from 'axios';
import { encryptFn, decryptFn } from './encryption';

const saveUserData = (data, pin) => {
  if (data !== null) {
    localStorage.setItem('u_data', encryptFn(JSON.stringify(data), pin));
  } else {
    localStorage.removeItem('u_data');
  }
};
const readUserData = (pin) => {
  try {
    const retrievedObject = localStorage.getItem('u_data');
    return JSON.parse(decryptFn(retrievedObject, pin)); 
  } catch (e) {
    return null;
  }
};
const logout = (pin) => {
  const user = readUserData(pin);
  try {
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/logout`,
      {},
      {
        headers: {
          authorization: `Bearer ${user?.token}`,
          Accept: 'application/json'
        }
      }
    );
  } finally {
    saveUserData(null);
    window.location.reload();
  }
};

export { saveUserData, readUserData, logout };
