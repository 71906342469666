const encryptFile = (file, encryptFn) => {
  const reader = new FileReader();
    
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    
    reader.onload = (e) => {
      const encrypted = encryptFn(e.target.result);
      const encryptedFile = new File([encrypted], `${file.name}`, { type: file.type, lastModified: file.lastModified });
      resolve(encryptedFile);
    };
    reader.readAsDataURL(file);
  });
};

const decryptFile = (file, type, decryptFn) => {
  const reader = new FileReader();
      
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
      
    reader.onload = async (e) => {
      const decrypted = decryptFn(e.target.result);
      if (decrypted) {
        const base64Response = await fetch(decrypted);
        const blob = await base64Response.blob();

        resolve(blob);
      } else {
        resolve(null);
      }
    };
    reader.readAsText(file);
  });
};

export { encryptFile, decryptFile };
