import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Typography,
  Space,
  Divider,
  QRCode,
  Empty,
  App
} from 'antd';
import { debounce } from 'lodash';
import { QrScanner } from '@yudiel/react-qr-scanner';
import PinInput from '../../common/PinInput';
import convertToNato from '../../../lib/natoPhonetic';
import { encryptFn, decryptFn } from '../../../lib/encryption';

const { Text } = Typography;

const ChannelEncPrompt = ({
  id, value, open, onCreate, onCancel 
}) => {
  const [code, setCode] = useState();

  const [showQR, setShowQR] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [scanErr, setScanErr] = useState('');

  const { message } = App.useApp();

  useEffect(() => {
    setCode(value);
  }, [value]);

  const handleShowQR = () => {
    setShowScanner(false);
    setShowQR((prev) => !prev);
  };
  const handleShowScanner = () => {
    setShowQR(false);
    setShowScanner((prev) => !prev);
    setScanErr('');
  };

  const showScanSuccess = debounce(() => {
    message.success('Encryption passphrase imported');
  }, 500);

  const handleScan = (data) => {
    if (data?.text) {
      setShowScanner(false);
      const decrypted = decryptFn(data?.text, process.env.APP_INTERNAL_ENC);
      setCode(decrypted);
      showScanSuccess();
    }
  };

  return (
    <Modal
      open={open}
      title={`Channel Local Encryption of /C-${id}`}
      footer={[
        <Button key="reset" onClick={() => setCode(null)}>Reset</Button>,
        <Button key="save" type="primary" onClick={() => onCreate(code)}>Save</Button>
      ]}
      onCancel={() => {
        onCancel();
      }}
    >
      <div style={{ marginTop: 20, minHeight: 200 }}>
        <PinInput length={10} value={code} type="alphanumeric" onPinChange={(data) => setCode(data.join(''))} style={{ marginBottom: 20 }} />
        <Text code type="warning" className="alph-text" strong>{convertToNato(code, '-')}</Text>
        <Divider />
        <Space.Compact block>
          <Button style={{ width: '100%' }} onClick={handleShowQR}>Generate QR</Button>
          <Button style={{ width: '100%' }} onClick={handleShowScanner}>Scan QR</Button>
        </Space.Compact>
        {showQR && (
          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
            {code && code !== '' ? (
              <QRCode value={encryptFn(code, process.env.APP_INTERNAL_ENC)} />
            ) : (
              <Empty />
            )}
          </div>
        )}
        {showScanner && (
          <div style={{
            display: 'flex', justifyContent: 'center', gap: 20, flexDirection: 'column', marginTop: 20 
          }}
          >
            <QrScanner
              hideCount
              onResult={handleScan}
              onError={(error) => setScanErr(error?.message)}
            />
            <p style={{ color: '#cf1322', textAlign: 'center' }}>{scanErr}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ChannelEncPrompt;
