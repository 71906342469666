import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Button, Space, Typography, App
} from 'antd';

import useGlobalUserState from '../../../lib/useGlobalUserState';
import Content from '../../common/Content';
import PinInput from '../../common/PinInput';
import { encryptFn, decryptFn } from '../../../lib/encryption';

const { Text, Paragraph } = Typography;

const RegisterPin = () => {
  const { message } = App.useApp();

  const [pin1, setPin1] = useState(null);
  const [pin2, setPin2] = useState(null);
  const setup = () => {
    if (pin1 === pin2 && pin1?.length === 4) {
      const preparedSign = encryptFn('<<ETACC_UAP_SIGN>>', String(pin1));
      window.localStorage.setItem('uap_sign', preparedSign);
      window.location.reload();
    } else {
      message.error('PINs are not equal');
    }
  };

  return (
    <Space direction="vertical" style={{ textAlign: 'center' }}>
      <h1>Setup App PIN</h1>
      <br />
      <Text>Enter new PIN:</Text>
      <PinInput length={4} mask onPinChange={(data) => setPin1(data.join(''))} /> 
      <Text>Repeat new PIN:</Text>
      <PinInput length={4} mask onPinChange={(data) => setPin2(data.join(''))} /> 
      <Button onClick={setup} type="primary" style={{ width: '100%', marginTop: 15 }}>Confirm</Button>
    </Space>
  );
};

const LoginPin = () => {
  const { setPin } = useGlobalUserState();
  const { message } = App.useApp();
  const [err, setErr] = useState('');

  const validatePin = (val) => decryptFn(window.localStorage.getItem('uap_sign'), val) === '<<ETACC_UAP_SIGN>>';
  const authUser = (val) => {
    setErr('');
    const valid = validatePin(val);
    if (valid) {
      setPin(val);
      message.success('Unlocked');
    } else {
      const errMsg = 'Invalid PIN provided';
      message.error(errMsg);
      setErr(errMsg);
    }
  };
  return (
    <div style={{ height: 100, textAlign: 'center' }}>
      <h2>Enter PIN</h2>
      <PinInput length={4} mask onPinComplete={authUser} autoFocus />
      <Paragraph style={{ marginTop: 20, textAlign: 'center' }} type="danger"> 
        {err}
      </Paragraph>
    </div>
  );
};

const Home = () => {
  const { pin } = useGlobalUserState();
  const uapSign = window.localStorage.getItem('uap_sign');

  if (pin) {
    return <Navigate to="/login" />;
  }

  return (
    <Content style={{
      maxWidth: '600px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 10
    }}
    >
      {uapSign ? <LoginPin /> : <RegisterPin />}
    </Content>
  );
};
export default Home;
