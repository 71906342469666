import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import rabbit from 'crypto-js/rabbit';
import SHA512 from 'crypto-js/sha512';
import MD5 from 'crypto-js/md5';
import enc2 from 'crypto-js/enc-base64';

const encryptFn = (text, pass) => {
  const preparedPassSHA512 = SHA512(pass).toString(enc2);
  const preparedPassMD5 = MD5(pass).toString(enc2);
  const res1 = aes.encrypt(text, preparedPassSHA512).toString();
  const res2 = rabbit.encrypt(res1, preparedPassMD5).toString();
  return res2;
};
const decryptFn = (text, pass) => {
  try {
    const preparedPassSHA512 = SHA512(pass).toString(enc2);
    const preparedPassMD5 = MD5(pass).toString(enc2);
    const res1 = rabbit.decrypt(text, preparedPassMD5).toString(enc);
    const res2 = aes.decrypt(res1, preparedPassSHA512).toString(enc);
    return res2;
  } catch (error) {
    return false;
  }
};
export { encryptFn, decryptFn };
