import { Navigate, Outlet } from 'react-router-dom';
import useGlobalUserState from '../../lib/useGlobalUserState';

const ProtectedRoute = ({
  redirectPath = '/',
  children
}) => {
  const { user, pin } = useGlobalUserState();
  if (user === undefined || pin === undefined) {
    return <div>Loading...</div>;
  }
  if (!user?.token || !pin) {
    return <Navigate to={redirectPath} replace />;
  }
  
  return children || <Outlet />;
};
export default ProtectedRoute;
