import { useState, useEffect } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { Input, Space } from 'antd';

const PinInput = ({
  length = 4, value, autoFocus = false, type = 'numeric', disabled = false, mask = false, error = false, style = {},
  onPinChange = () => {}, onPinComplete = () => {}
}) => {
  const [values, setValues] = useState(value ? value.split('') : Array(length).fill(''));
  useEffect(() => {
    setValues([...value?.split('') || [], ...Array(length - (value?.length || 0)).fill('')]);
  }, [value]);
  const { fields } = usePinInput({
    values,
    autoFocus,
    type,
    disabled,
    mask,
    error,
    onChange: (values) => {
      setValues(values);
      onPinChange(values);
    },
    onComplete: onPinComplete
  });
  
  return (
    <div className="pin-input" style={style}>
      <Space.Compact block size="large">
        {fields.map((fieldProps, index) => (
          <Input
            size="large"
            key={index}
            className="pin-input__field"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...fieldProps}
            style={{
              maxWidth: 60, textAlign: 'center', minHeight: 60, padding: 0 
            }}
          />
        ))}  
      </Space.Compact>
    </div>
  );
};

export default PinInput;
