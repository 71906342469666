import { useState, useEffect } from 'react';

import {
  Col, Row, App,
  Button, Form, Input, Divider, Slider
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import Content from '../../common/Content';
import useGlobalUserState from '../../../lib/useGlobalUserState';

const Settings = () => {
  const { message } = App.useApp();
  const { user } = useGlobalUserState();

  const [loadingPass, setLoadingPass] = useState(false);
  const [passError, setPassErr] = useState(null);
  const [form] = Form.useForm();

  const onPassChange = async (data) => {
    setPassErr(null);
    if (data?.newPassword === data?.newPassword2) {
      setLoadingPass(true);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/changePassword`,
          {
            current_password: data?.currentPassword,
            new_password: data?.newPassword
          },
          {
            headers: {
              authorization: `Bearer ${user.token}`,
              Accept: 'application/json'
            }
          }
        );
        if (res?.data?.status) {
          setPassErr(null);
          message.success('Password has been changed!');
          form.resetFields();
        }
      } catch (e) {
        setPassErr(`Error - ${e?.response?.status}`);
      } finally {
        setLoadingPass(false);
      }
    } else {
      setPassErr('New passwords are not equal.');
    }
  };

  const [refreshingInterval, setRefreshingInterval] = useState(null);

  useEffect(() => {
    const value = window.localStorage.getItem('refreshing_interval');
    setRefreshingInterval(parseInt(value, 10) || 10);
  }, []);

  const handleChangeRefreshingInterval = (value) => {
    setRefreshingInterval(value);
    window.localStorage.setItem('refreshing_interval', value);
  };

  const clearPWACache = () => {
    caches.keys().then((names) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const name of names) { caches.delete(name); }
      window.location.href = './';
    });
  };

  // const [loadingU2f, setLoadingU2F] = useState(false);
  // const [U2FError, setU2FErr] = useState(null);

  // const addU2F = async () => {
  //   setLoadingU2F(true);
  //   try {
  //     // createRegistration(user.token);
  //   } catch (e) {
  //     setU2FErr(e?.message);
  //   } finally {
  //     setLoadingU2F(false);
  //   }
  // };
  return (
    <Content>
      <h3 style={{ textAlign: 'center' }}>
        Signed as
        {' '}
        {user?.email}
      </h3>
      <Divider style={{ marginTop: 10 }} />
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <h1 style={{ marginBottom: 20 }}>Change Password</h1>
          <Form
            name="changePassword"
            onFinish={onPassChange}
            size="large"
            disabled={loadingPass}
            form={form}
          >
            <Form.Item
              name="currentPassword"
              rules={[{ required: true, message: '' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="current password"
              />
            </Form.Item>

            <Form.Item
              name="newPassword"
              rules={[{ required: true, message: '', min: 5 }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="new password"
              />
            </Form.Item>

            <Form.Item
              name="newPassword2"
              rules={[{ required: true, message: '' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="repeat new password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loadingPass}>
                Change password
              </Button>
            </Form.Item>
            <p style={{ color: '#cf1322', textAlign: 'center' }}>{passError}</p>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <h1 style={{ marginBottom: 20 }}>WebAuthn</h1>
          {/* <Button onClick={addU2F} loading={loadingU2f}>Register new U2F device</Button> */}
          <Button disabled>Register new U2F device - now available yet</Button>
        </Col>
        <Col xs={24} lg={12}>
          <h1 style={{ marginBottom: 20, marginTop: 20 }}>Message Refreshing Interval</h1>
          <div style={{ maxWidth: 400, marginBottom: 20 }}>
            <Slider
              min={5} 
              max={60}
              step={5}
              tooltip={{ open: true, placement: 'right', formatter: (val) => `${val} s` }}
              onChange={handleChangeRefreshingInterval}
              value={refreshingInterval}
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <h1 style={{ marginBottom: 20, marginTop: 20 }}>Other Settings</h1>
          <Button onClick={clearPWACache}>clear PWA cache</Button>
        </Col>
      </Row>
   
    </Content>
  );
};
export default Settings;
