import { useState, useEffect } from 'react';
import {
  Modal, Button, Typography, Divider, App, Statistic, Spin
} from 'antd';
import dayjs from 'dayjs';
import { setWith } from 'lodash';
import PinInput from '../../common/PinInput';
import { decryptFn } from '../../../lib/encryption';
import { burnConversations } from '../../../lib/requestHandler';
import useGlobalUserState from '../../../lib/useGlobalUserState';
import { readLocalEncryption, saveLocalEncryption } from '../../../lib/localEncryptionHandler';

const { Text } = Typography;
const { Countdown } = Statistic;

const BurnProtocolPrompt = ({
  open, data, onSuccess, onCancel 
}) => {
  const { user, pin } = useGlobalUserState();
  const [isPINAuthorized, setIsPINAuthorized] = useState(false);
  const [isProtocolActivated, setIsProtocolActivated] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [emergencyStop, setEmergencyStop] = useState(false);
  const [isBurning, setIsBurning] = useState(false);
  const [isBurned, setIsBurned] = useState(false);

  const { message } = App.useApp();

  useEffect(() => {
    if (!open) {
      setIsPINAuthorized(false);
      setIsProtocolActivated(false);
      setEmergencyStop(false);
      setIsBurning(false);
      setIsBurned(false);
    }
  }, [open]);

  const validatePin = (val) => decryptFn(window.localStorage.getItem('uap_sign'), val) === '<<ETACC_UAP_SIGN>>';
  const authUser = (val) => {
    const valid = validatePin(val);
    if (valid) {
      setIsPINAuthorized(true);
      message.success('Identity confirmed');
    } else {
      message.error('Invalid PIN provided');
    }
  };

  const handleActivateProtocol = () => {
    setDeadline(dayjs().add(5, 'seconds'));
    setIsProtocolActivated(true);
  };
  const handleReachZero = async () => {
    setIsBurning(true);
    setTimeout(async () => {
      if (!emergencyStop && isProtocolActivated) {
        const res = await burnConversations(user?.token, data);

        data.forEach((id) => {
          const obj = readLocalEncryption(pin) || {};
          setWith(obj, [user?.id, id], null, Object);
          saveLocalEncryption(obj, pin);
        });

        if (res?.data?.status) {
          setIsBurned(true);
          setIsBurning(false);
          message.success('BURN COMPLETED');
          onSuccess();
        }
      }
    }, 1000);
  };

  const disarmProtocol = () => {
    setEmergencyStop(true);
    setIsProtocolActivated(false);
    message.error('PROTOCOL DISARMED');
  };

  return (
    <Modal
      open={open}
      title={<h3 style={{ fontWeight: 'bold' }}>BURNING PROTOCOL</h3>}
      footer={[]}
      onCancel={() => {
        onCancel();
      }}
    >
      <Text>
        Selected channels to burn:
        {' '}
        <Text code>
          {data.join(', ')}
        </Text>
      </Text>
      <Divider />
      {!isPINAuthorized ? (
        <div style={{
          display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 10
        }}
        >
          <h3>Confirm your identity</h3>
          <PinInput length={4} mask onPinComplete={authUser} autoFocus />
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          {!isProtocolActivated ? (
            <>
              <h1 style={{ fontWeight: 800 }}>DISARMED</h1>
              <Button
                type="primary"
                size="large"
                block
                style={{ minHeight: 50, fontWeight: 'bold' }}
                danger
                onClick={handleActivateProtocol}
                disabled={emergencyStop}
              >
                BURN
              </Button>
            </>

          ) : (
            <>
              <h1 className="armedH1" style={{ fontWeight: 800 }}>
                {isBurned ? 'BURNED' : 'ARMED'}
              </h1>
              <Countdown title="Burning in" value={deadline} format="HH:mm:ss:SSS" onFinish={handleReachZero} />
              {!isBurning && !isBurned && (
                <Button style={{ marginTop: 10 }} size="large" block danger onClick={disarmProtocol}>CANCEL</Button>
              )}
              {isBurning && (
                <Spin size="large" />
              )}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
export default BurnProtocolPrompt;
