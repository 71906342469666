import { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, App
} from 'antd';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Content from '../common/Content';
import useGlobalUserState from '../../lib/useGlobalUserState';

const Login = () => {
  const { user, setUser, pin } = useGlobalUserState();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);

  const { message } = App.useApp();

  const onSubmit = async (data) => {
    setLoading(true);
    setErr(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email: data.username,
          password: data.password
        }
      );

      if (response?.data?.token) {
        setUser(response.data);
        message.success('Successfully signed in.');
      }
      setErr(null);
    } catch (error) {
      setErr(error?.response?.data?.message ?? error.response.statusText);
    } finally {
      setLoading(false);
    }
  };
  if (!pin && pin !== undefined) {
    return <Navigate to="/" replace />;
  }
  if (user?.token) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <Content style={{ maxWidth: '600px' }}>
      <Form
        name="login"
        onFinish={onSubmit}
        size="large"
        disabled={loading}
      >
        <Form.Item
          name="username"
          rules={[{ required: true }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Username" type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
            Log in
          </Button>
        </Form.Item>
        <p style={{ color: '#cf1322', textAlign: 'center' }}>{err}</p>
      </Form>
    </Content>
  );
};
export default Login;
