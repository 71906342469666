import {
  Layout
} from 'antd';
  
const {
  Content: Cnt
} = Layout;
  
const Content = ({ children, style }) => (
  <Cnt
    className="content"
    style={{
      padding: '24px', background: '#262626', borderRadius: 8, minHeight: 280, maxWidth: '95%', marginTop: 50, ...style
    }}
  >
    {children}
  </Cnt>
);

export default Content;
